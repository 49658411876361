import React from "react";

export const Privacy = () => <div className="privacy">
    <h2>Privacy policy</h2>
    <br/>1.	Introduction
    <br/>1.1	We are committed to safeguarding the privacy of our website visitors, service users, individual customers and customer personnel.
    <br/>1.2	This policy applies where we are acting as a data controller with respect to the personal data of such persons; in other words, where we determine the purposes and means of the processing of that personal data.
    <br/>1.3	Our website incorporates privacy controls which affect how we will process your personal data. By using the privacy controls, you can specify whether you would like to receive direct marketing communications and limit the collection, sharing and publication of your personal data. You can access the privacy controls via URL.
    <br/>1.4	We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.
    <br/>1.5	In this policy, "we", "us" and "our" refer to Meeting Pro. For more information about us, see Section 14.
    <br/>2.	Credit
    <br/>2.1	This document was created using a template from Docular (https://seqlegal.com/free-legal-documents/privacy-policy).
    <br/>You must retain the above credit. Use of this document without the credit is an infringement of copyright. However, you can purchase from us an equivalent document that does not include the credit.
    <br/>3.	The personal data that we collect
    <br/>3.1	In this Section 3 we have set out the general categories of personal data that we process and, in the case of personal data that we did not obtain directly from you, information about the source and specific categories of that data.
    <br/>3.2	We may process data enabling us to get in touch with you ("contact data"). The contact data may include your name, email address, telephone number, postal address and/or social media account identifiers. The source of the contact data is you and/or your employer. If you log into our website using a social media account, we will obtain elements of the contact data from the relevant social media account provider.
    <br/>3.3	We may process your website user account data ("account data"). The account data may include your account identifier, name, email address, business name, account creation and modification dates, website settings and marketing preferences. The primary source of the account data is you and/or your employer, although some elements of the account data may be generated by our website. If you log into our website using a social media account, we will obtain elements of the account data from the relevant social media account provider.
    <br/>3.4	We may process information relating to transactions, including purchases of goods and/or services, that you enter into with us and/or through our website ("transaction data"). The transaction data may include your name, your contact details, your payment card details (or other payment details) and the transaction details. The source of the transaction data is you and/or our payment services provider.
    <br/>3.5	We may process information contained in or relating to any communication that you send to us or that we send to you ("communication data"). The communication data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms.
    <br/>3.6	We may process data about your use of our website and services ("usage data"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system.
    <br/>3.7	We may process identify general category of data. This data may include list specific items of data. The source of this data is identify source.
    <br/>4.	Purposes of processing and legal bases
    <br/>4.1	In this Section 4, we have set out the purposes for which we may process personal data and the legal bases of the processing.
    <br/>4.2	Operations - We may process your personal data for the purposes of operating our website, the processing and fulfilment of orders, providing our services, supplying our goods, generating invoices, bills and other payment-related documentation, and credit control. The legal basis for this processing is our legitimate interests, namely the proper administration of our website, services and business OR the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract OR specify basis.
    <br/>4.3	Publications - We may process account data for the purposes of publishing such data on our website and elsewhere through our services in accordance with your express instructions. The legal basis for this processing is consent OR our legitimate interests, namely the publication of content in the ordinary course of our operations OR the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract OR specify basis.
    <br/>4.4	Relationships and communications - We may process contact data, account data, transaction data and/or communication data for the purposes of managing our relationships, communicating with you (excluding communicating for the purposes of direct marketing) by email, SMS, post, fax and/or telephone, providing support services and complaint handling. The legal basis for this processing is our legitimate interests, namely communications with our website visitors, service users, individual customers and customer personnel, the maintenance of relationships, and the proper administration of our website, services and business OR specify basis.
    <br/>4.5	Direct marketing - We may process contact data, account data and/or transaction data for the purposes of creating, targeting and sending direct marketing communications by email, SMS, post and/or fax and making contact by telephone for marketing-related purposes. The legal basis for this processing is consent OR our legitimate interests, namely promoting our business and communicating marketing messages and offers to our website visitors and service users OR specify basis.
    <br/>4.6	Research and analysis - We may process usage data and/or transaction data for the purposes of researching and analysing the use of our website and services, as well as researching and analysing other interactions with our business. The legal basis for this processing is consent OR our legitimate interests, namely monitoring, supporting, improving and securing our website, services and business generally OR specify basis.
    <br/>4.7	Record keeping - We may process your personal data for the purposes of creating and maintaining our databases, back-up copies of our databases and our business records generally. The legal basis for this processing is our legitimate interests, namely ensuring that we have access to all the information we need to properly and efficiently run our business in accordance with this policy.
    <br/>4.8	Security - We may process your personal data for the purposes of security and the prevention of fraud and other criminal activity. The legal basis of this processing is our legitimate interests, namely the protection of our website, services and business, and the protection of others.
    <br/>4.9	Insurance and risk management - We may process your personal data where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks and/or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.
    <br/>4.10	Legal claims - We may process your personal data where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.
    <br/>4.11	Legal compliance and vital interests - We may also process your personal data where such processing is necessary for compliance with a legal obligation to which we are subject or in order to protect your vital interests or the vital interests of another natural person.
    <br/>5.	Providing your personal data to others
    <br/>5.1	We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice.
    <br/>5.2	Your personal data held in our website database OR Identify personal data category or categories will be stored on the servers of our hosting services providers identified at URL.
    <br/>5.3	We may disclose specify personal data category or categories to our suppliers or subcontractors identified at URL insofar as reasonably necessary for specify purposes.
    <br/>5.4	Financial transactions relating to our website and services are OR may be handled by our payment services providers, identify PSPs. We will share transaction data with our payment services providers only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds. You can find information about the payment services providers' privacy policies and practices at URLs.
    <br/>5.5	In addition to the specific disclosures of personal data set out in this Section 5, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise, or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.
    <br/>6.	International transfers of your personal data
    <br/>6.1	In this Section 6, we provide information about the circumstances in which your personal data may be transferred to a third country under UK data protection law OR EU data protection law OR UK and/or EU data protection law.
    <br/>6.2	We may transfer your personal data from the European Economic Area (EEA) to the UK and process that personal data in the UK for the purposes set out in this policy, and may permit our suppliers and subcontractors to do so, during any period with respect to which the UK is not treated as a third country under EU data protection law or benefits from an adequacy decision under EU data protection law; and we may transfer your personal data from the UK to the EEA and process that personal data in the EEA for the purposes set out in this policy, and may permit our suppliers and subcontractors to do so, during any period with respect to which EEA states are not treated as third countries under UK data protection law or benefit from adequacy regulations under UK data protection law.
    <br/>6.3	The hosting facilities for our website are situated in specify countries. The competent data protection authorities have made an adequacy determination with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the competent data protection authorities, a copy of which you can obtain from source OR specify appropriate safeguards and means to obtain a copy.
    <br/>6.4	Specify category or categories of supplier or subcontractor is OR are situated in specify countries. The competent data protection authorities have made an adequacy determination with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the competent data protection authorities, a copy of which can be obtained from source OR specify appropriate safeguards and means to obtain a copy.
    <br/>6.5	You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.
    <br/>7.	Retaining and deleting personal data
    <br/>7.1	This Section 7 sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.
    <br/>7.2	Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.
    <br/>7.3	We will retain your personal data as follows:
    <br/>(a)	contact data will be retained for a minimum period of period following the date of the most recent contact between you and us, and for a maximum period of period following that date;
    <br/>(b)	account data will be retained for a minimum period of period following the date of closure of the relevant account, and for a maximum period of period following that date;
    <br/>(c)	transaction data will be retained for a minimum period of period following the date of the transaction, and for a maximum period of period following that date;
    <br/>(d)	communication data will be retained for a minimum period of period following the date of the communication in question, and for a maximum period of period following that date;
    <br/>(e)	usage data will be retained for period following the date of collection; and
    <br/>(f)	data category will be retained for a minimum period of period following date, and for a maximum period of period following date.
    <br/>additional list items
    <br/>7.4	Notwithstanding the other provisions of this Section 7, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.
    <br/>8.	Your rights
    <br/>8.1	In this Section 8, we have listed the rights that you have under data protection law.
    <br/>8.2	Your principal rights under data protection law are:
    <br/>(a)	the right to access - you can ask for copies of your personal data;
    <br/>(b)	the right to rectification - you can ask us to rectify inaccurate personal data and to complete incomplete personal data;
    <br/>(c)	the right to erasure - you can ask us to erase your personal data;
    <br/>(d)	the right to restrict processing - you can ask us to restrict the processing of your personal data;
    <br/>(e)	the right to object to processing - you can object to the processing of your personal data;
    <br/>(f)	the right to data portability - you can ask that we transfer your personal data to another organisation or to you;
    <br/>(g)	the right to complain to a supervisory authority - you can complain about our processing of your personal data; and
    <br/>(h)	the right to withdraw consent - to the extent that the legal basis of our processing of your personal data is consent, you can withdraw that consent.
    <br/>8.3	These rights are subject to certain limitations and exceptions. You can learn more about the rights of data subjects by visiting https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices_en and https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/.
    <br/>8.4	You may exercise any of your rights in relation to your personal data by written notice to us, using the contact details set out below.
    <br/>9.	About cookies
    <br/>9.1	A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.
    <br/>9.2	Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.
    <br/>9.3	Cookies may not contain any information that personally identifies a user, but personal data that we store about you may be linked to the information stored in and obtained from cookies.
    <br/>10.	Cookies that we use
    <br/>10.1	We use cookies for the following purposes:
    <br/>(a)	authentication and status - we use cookies to identify you when you visit our website and as you navigate our website, and to help us determine if you are logged into our website (cookies used for this purpose are: identify cookies);
    <br/>(b)	shopping cart - we use cookies to maintain the state of your shopping cart as you navigate our website (cookies used for this purpose are: identify cookies);
    <br/>(c)	personalisation - we use cookies to store information about your preferences and to personalise our website for you (cookies used for this purpose are: identify cookies);
    <br/>(d)	security - we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally (cookies used for this purpose are: identify cookies);
    <br/>(e)	advertising - we use cookies to help us to display advertisements that will be relevant to you (cookies used for this purpose are: identify cookies);
    <br/>(f)	analysis - we use cookies to help us to analyse the use and performance of our website and services (cookies used for this purpose are: identify cookies); and
    <br/>(g)	cookie consent - we use cookies to store your preferences in relation to the use of cookies more generally (cookies used for this purpose are: identify cookies).
    <br/>additional list items
    <br/>11.	Cookies used by our service providers
    <br/>11.1	Our service providers use cookies and those cookies may be stored on your computer when you visit our website.
    <br/>11.2	We use Google Analytics. Google Analytics gathers information about the use of our website by means of cookies. The information gathered is used to create reports about the use of our website. You can find out more about Google's use of information by visiting https://www.google.com/policies/privacy/partners/ and you can review Google's privacy policy at https://policies.google.com/privacy. The relevant cookies are: identify cookies.
    <br/>11.3	We use identify service provider to specify service. This service uses cookies for specify purpose(s). You can view the privacy policy of this service provider at URL. The relevant cookies are: identify cookies.
    <br/>12.	Managing cookies
    <br/>12.1	Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:
    <br/>(a)	https://support.google.com/chrome/answer/95647 (Chrome);
    <br/>(b)	https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop (Firefox);
    <br/>(c)	https://help.opera.com/en/latest/security-and-privacy/ (Opera);
    <br/>(d)	https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies (Internet Explorer);
    <br/>(e)	https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac (Safari); and
    <br/>(f)	https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy (Edge).
    <br/>additional list items
    <br/>12.2	Blocking all cookies will have a negative impact upon the usability of many websites.
    <br/>12.3	If you block cookies, you will not be able to use all the features on our website.
    <br/>13.	Amendments
    <br/>13.1	We may update this policy from time to time by publishing a new version on our website.
    <br/>13.2	You should check this page occasionally to ensure you are happy with any changes to this policy.
    <br/>13.3	We may OR will notify you of changes OR significant changes to this policy by email.
    <br/>14.	Our details
    <br/>14.1	This website is owned and operated by name.
    <br/>14.2	We are registered in England and Wales under registration number number, and our registered office is at address.
    <br/>14.3	Our principal place of business is at address.
    <br/>14.4	You can contact us:
    <br/>(a)	by post, to the postal address given above;
    <br/>(b)	using our website contact form;
    <br/>(c)	by telephone, on the contact number published on our website; or
    <br/>(d)	by email, using the email address published on our website.
    <br/>additional list items
    <br/>15.	Representatives
    <br/>15.1	Our representative within the EU with respect to our obligations under data protection law is identify representative and you can contact our representative by contact details.
    <br/>15.2	Our representative within the UK with respect to our obligations under data protection law is identify representative and you can contact our representative by contact details.
    <br/>16.	Data protection officer
    <br/>16.1	Our data protection officer's contact details are: contact details.
    <br/>Free privacy policy: drafting notes
    <br/>This is a website privacy policy template. It may be used in relation to many different types of website.
    <br/>The main purpose of a privacy policy is to help a website operator to comply with information disclosure obligations under data protection legislation. Across the EU, that means compliance with the General Data Protection Regulation (GDPR). Within the UK, the Data Protection Act 2018, which adapts the GDPR for the UK context, applies. Failure to comply with data protection legislation may lead to civil liability and/or criminal law penalties.
    <br/>This privacy policy is a shorter version of our privacy and cookies policy document. That document is more flexible than this policy, although at the cost of greater complexity.
    <br/>To complete this template, you will need detailed information about how you or your organisation uses personal data. For example, you will need to know what personal data is processed, the purposes for which that personal data is used, the persons or categories of persons to whom that personal data may be disclosed and the periods for which that personal data will be retained. You will also need to establish the legal bases of the your processing.
    <br/>Separate rules regulate the provision of information about cookies, and this document includes optional provisions dealing with cookie-related disclosures. If you retain these provisions, you will need to know the purposes for which cookies and similar technologies are used on your website.
    <br/>You should consider whether you need to take specialist legal advice on data protection.
    <br/>If you collect sensitive personal information (such as information about a person's health, sexuality or political affiliations), or if you collect personal information from children or about children, you should always take advice before using this (or indeed any other) privacy policy template. In any case, use of a privacy policy is only one aspect of data protection compliance.
    <br/>In many areas, the EU GDPR and the UK GDPR are identical; in other areas, the differences are limited to jurisdictional and governmental references. Also, the text of the UK GDPR is not set out in a single piece of legislation. For these reasons, we have generally referenced the EU GDPR rather than the UK GDPR in the notes to this document, although in some areas both are covered. See the Keeling Schedule for a readable version of the UK GDPR (https://www.gov.uk/government/publications/data-protection-law-eu-exit).
    <br/>* Regulation (EU) 2016/679 (General Data Protection Regulation) - https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32016R0679
    <br/>* Guidelines on transparency under Regulation 2016/679, European Data Protection Board - https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=622227
    <br/>* The right to be informed, Guide to the GDPR, UK Information Commissioner's Office - https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/the-right-to-be-informed/
    <br/>Section 1: Introduction
    <br/>These introductory provisions may be used to draw individuals' attention to some of the key issues addressed in the document.
    <br/>Section 1.1
    
    
    <br/>Section 1.2
    <br/>"Personal data" is defined in Article 4(1) of the GDPR:
    <br/>"'personal data' means any information relating to an identified or identifiable natural person ('data subject'); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person".
    <br/>Section 1.3
    
    
    <br/>Section 1.4
    
    
    <br/>The inclusion of this statement in your privacy policy will not in itself satisfy the requirements of the ePrivacy Directive and/or the Privacy and Electronic Communications (EC Directive) Regulations 2003 as regards consent to the use of cookies. Guidance concerning methods of obtaining such consent under UK law is included on the Information Commissioner's website.
    <br/>* Cookies and similar technologies, Guide to PECR, UK Information Commissioner's Office - https://ico.org.uk/for-organisations/guide-to-pecr/cookies-and-similar-technologies/
    <br/>* Directive 2002/58/EC (Directive on privacy and electronic communications) - https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32002L0058&from=EN
    <br/>* Privacy and Electronic Communications (EC Directive) Regulations 2003 (original form) - http://www.legislation.gov.uk/uksi/2003/2426/made
    <br/>Section 1.5
    
    
    <br/>Section 2: Credit
    <br/>Section: Free documents licensing warning
    
     Although you need to retain the credit, you should remove the inline copyright warning from this document before use.
    <br/>Section 3: The personal data that we collect
    <br/>The GDPR requires that controllers disclose to data subjects certain information about the personal data that they collect, in particular where the data does not come direct from the data subject.
    <br/>These provisions are designed to help with this requirement, and also to help you to categorise the personal data collected in a way that makes it easy to differentiate between types of personal data elsewhere in the document.
    <br/>As you will see, there is quite a lot of overlap between the different categories that we suggest. For example, individual names could fall into several different categories. In editing these provisions, you should retain those categories of data that most closely reflect the organisation of data in your business, delete the others, and add new categories as necessary.
    <br/>Turning to the legislative provisions, Article 14(1) of the GDPR provides that:
    <br/>"Where personal data have not been obtained from the data subject, the controller shall provide the data subject with the following information: ... (d) the categories of personal data concerned ...".
    <br/>Article 14(2) of the GDPR, which also applies in the case that the personal data have not been obtained from the data subject, provides that:
    <br/>"In addition to the information referred to in paragraph 1, the controller shall provide the data subject with the following information necessary to ensure fair and transparent processing in respect of the data subject: ... (f) from which source the personal data originate, and if applicable, whether it came from publicly accessible sources ... ".
    <br/>As regards the identification of the source of personal data in the case that the personal data is not obtained from the data subject, the guidance from the European Data Protection Board states that:
    <br/>"The specific source of the data should be provided unless it is not possible to do so … . If the specific source is not named then information provided should include: the nature of the sources (i.e. publicly / privately held sources) and the types of organisation / industry / sector."
    <br/>* Article 13, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-13-gdpr/
    <br/>* Article 14, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-14-gdpr/
    <br/>* Guidelines on transparency under Regulation 2016/679, European Data Protection Board - https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=622227
    <br/>Section 3.2
    
    
    <br/>Section 3.3
    
    
    <br/>Section 3.4
    
    
    <br/>Section 3.5
    
    
    <br/>Section 3.6
    
    
    <br/>Section 3.7
    
    
    <br/>Use this form of provision to identify other categories of personal data that may be processed.
    <br/>Section 4: Purposes of processing and legal bases
    <br/>The GDPR requires that controllers disclose to data subjects detailed information about the purposes and legal bases of their processing of personal data.
    <br/>We have suggested some typical purposes here, but you may need to remove some of the suggested ones and add your own. Equally, while we have suggested some possible legal bases of processing in relation to each identified purpose or set of purposes, the most appropriate legal basis will depend upon your specific circumstances.
    <br/>The relevant requirements are set out in Articles 13 and 14 of the GDPR.
    <br/>Article 13(1) of the GDPR provides that:
    <br/>"Where personal data relating to a data subject are collected from the data subject, the controller shall, at the time when personal data are obtained, provide the data subject with all of the following information: ... (c) the purposes of the processing for which the personal data are intended as well as the legal basis for the processing; (d) where the processing is based on point (f) of Article 6(1), the legitimate interests pursued by the controller or by a third party".
    <br/>Article 14(1) of the GDPR provides that:
    <br/>"Where personal data have not been obtained from the data subject, the controller shall provide the data subject with the following information: ... (c) the purposes of the processing for which the personal data are intended as well as the legal basis for the processing ...".
    <br/>Article 14(2) of the GDPR, which also applies in the case that the personal data have not been obtained from the data subject, provides that:
    <br/>"In addition to the information referred to in paragraph 1, the controller shall provide the data subject with the following information necessary to ensure fair and transparent processing in respect of the data subject: ... (b) where the processing is based on point (f) of Article 6(1), the legitimate interests pursued by the controller or by a third party ... ".
    <br/>Article 6(1)(f) of the GDPR, which is referred to in Articles 13 and 14, provides that:
    <br/>"(1) Processing shall be lawful only if and to the extent that at least one of the following applies: ... (f) processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data, in particular where the data subject is a child."
    <br/>The UK Information Commissioner's Office website provides useful guidance in relation to the selection of the legal bases for processing.
    <br/>* Article 13, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-13-gdpr/
    <br/>* Article 14, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-14-gdpr/
    <br/>* Article 6, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-6-gdpr/
    <br/>* Guidelines on transparency under Regulation 2016/679, European Data Protection Board - https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=622227
    <br/>* Lawful basis of processing, Guide to the GDPR, UK Information Commissioner's Office - https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/
    <br/>Section 4.2
    
    
    <br/>Section 4.3
    
    
    <br/>Section 4.4
    
    
    <br/>Section 4.5
    
    
    <br/>Section 4.6
    
    
    <br/>Section 4.7
    
    
    <br/>Section 4.8
    
    
    <br/>Section 4.9
    
    
    <br/>Section 4.10
    
    
    <br/>Section 4.11
    
    
    <br/>Section 5: Providing your personal data to others
    <br/>Article 13(1)(e) of the GDPR requires that where personal data are collected from the data subject, the data controller must provide the data subject with information about "the recipients or categories of recipients of the personal data".
    <br/>Equivalent rules for data collected from someone other than the data subject are in Article 14(1)(e).
    <br/>Although the GDPR refers to "categories of recipients", the guidance from the European Data Protection Board on this subject states:
    <br/>"The term 'recipient' is defined in Article 4.9 as 'a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not' emphasis added. As such, a recipient does not have to be a third party. Therefore, other data controllers, joint controllers and processors to whom data is transferred or disclosed are covered by the term 'recipient' and information on such recipients should be provided in addition to information on third party recipients. The actual (named) recipients of the personal data, or the categories of recipients, must be provided. In accordance with the principle of fairness, controllers must provide information on the recipients that is most meaningful for data subjects. In practice, this will generally be the named recipients, so that data subjects know exactly who has their personal data. If controllers opt to provide the categories of recipients, the information should be as specific as possible by indicating the type of recipient (i.e. by reference to the activities it carries out), the industry, sector and sub-sector and the location of the recipients."
    <br/>* Article 13, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-13-gdpr/
    <br/>* Article 14, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-14-gdpr/
    <br/>* Guidelines on transparency under Regulation 2016/679, European Data Protection Board - https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=622227
    <br/>Section 5.1
    
    
    <br/>Section 5.2
    
    
    <br/>Section 5.3
    
    
    <br/>Section 5.4
    
    
    <br/>Section 6: International transfers of your personal data
    
    
    <br/>Article 13(1)(f) of the GDPR requires that data controllers disclose to data subjects "where applicable, the fact that the controller intends to transfer personal data to a third country or international organisation and the existence or absence of an adequacy decision by the Commission, or in the case of transfers referred to in Article 46 transfers subject to appropriate safeguards or 47 binding corporate rules, or the second subparagraph of Article 49(1) limited transfers for compelling legitimate interests, reference to the appropriate or suitable safeguards and the means by which to obtain a copy of them or where they have been made available".
    <br/>The European Data Protection Board guidance on this issue states:
    <br/>"The relevant GDPR article permitting the transfer and the corresponding mechanism ... should be specified. Information on where and how the relevant document may be accessed or obtained should also be provided e.g. by providing a link to the mechanism used. In accordance with the principle of fairness, the information provided on transfers to third countries should be as meaningful as possible to data subjects; this will generally mean that the third countries be named."
    <br/>* Article 13, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-13-gdpr/
    <br/>* Guidelines on transparency under Regulation 2016/679, European Data Protection Board - https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=622227
    <br/>Section 6.2
    
    
    <br/>Section 6.3
    
    
    <br/>Section 6.4
    
    
    <br/>Section 6.5
    
     Will users have the opportunity to publish personal information on the website?
    <br/>Section 7: Retaining and deleting personal data
    <br/>Article 5(1)(e) of the GDPR sets out the storage limitation, one of the fundamental rules of the regime:
    <br/>"Personal data shall be: ... kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed; personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes in accordance with Article 89(1) subject to implementation of the appropriate technical and organisational measures required by this Regulation in order to safeguard the rights and freedoms of the data subject ... ".
    <br/>Article 13(2) of the GDPR provides, in relation to personal data collected from the data subject, that:
    <br/>"... the controller shall, at the time when personal data are obtained, provide the data subject with the following further information necessary to ensure fair and transparent processing: (a) the period for which the personal data will be stored, or if that is not possible, the criteria used to determine that period ...".
    <br/>Article 14(2) of the GDPR makes similar provision in relation to personal data that is not collected from the data subject.
    <br/>The European Data Protection Board guidance on this issue states:
    <br/>"This is linked to the data minimisation requirement in Article 5.1(c) and storage limitation requirement in Article 5.1(e). The storage period (or criteria to determine it) may be dictated by factors such as statutory requirements or industry guidelines but should be phrased in a way that allows the data subject to assess, on the basis of his or her own situation, what the retention period will be for specific data / purposes. It is not sufficient for the data controller to generically state that personal data will be kept as long as necessary for the legitimate purposes of the processing. Where relevant, the different storage periods should be stipulated for different categories of personal data and/or different processing purposes, including where appropriate, archiving periods."
    <br/>* Article 5, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-5-gdpr/
    <br/>* Article 13, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-13-gdpr/
    <br/>* Article 14, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-14-gdpr/
    <br/>* Guidelines on transparency under Regulation 2016/679, European Data Protection Board - https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=622227
    <br/>Section 8: Your rights
    <br/>Article 13(2) of the GDPR provides that, where personal data is collected from a data subject, certain information about data subject rights must be provided:
    <br/>"In addition to the information referred to in paragraph 1, the controller shall, at the time when personal data are obtained, provide the data subject with the following further information necessary to ensure fair and transparent processing: ... (b) the existence of the right to request from the controller access to and rectification or erasure of personal data or restriction of processing concerning the data subject or to object to processing as well as the right to data portability; (c) where the processing is based on point (a) of Article 6(1) or point (a) of Article 9(2), the existence of the right to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal; ...".
    <br/>Similar provisions are set out in Article 14 in relation to personal data which is not collected from the relevant data subject.
    <br/>The European Data Protection Board guidance on this issue states:
    <br/>"This information should be specific to the processing scenario and include a summary of what the right involves and how the data subject can take steps to exercise it and any limitations on the right … . In particular, the right to object to processing must be explicitly brought to the data subject's attention at the latest at the time of first communication with the data subject and must be presented clearly and separately from any other information."
    <br/>* Article 13, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-13-gdpr/
    <br/>* Article 14, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-14-gdpr/
    <br/>* Guidelines on transparency under Regulation 2016/679, European Data Protection Board - https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=622227
    <br/>Section 9: About cookies
    
    
    <br/>Under EU law, there are two additional requirements in relation to the use of cookies and similar technologies, which apply over-and-above the rules regulating the processing of personal data: a consent requirement and an information disclosure requirement. The provisions of this document relating to cookies are designed to aid compliance with the information disclosure requirement.
    <br/>This requirement derives from Article 5(3) of Directive 2002/58/EC of the European Parliament and of the Council of 12 July 2002 concerning the processing of personal data and the protection of privacy in the electronic communications sector (Directive on privacy and electronic communications), which provides that:
    <br/>"Member States shall ensure that the use of electronic communications networks to store information or to gain access to information stored in the terminal equipment of a subscriber or user is only allowed on condition that the subscriber or user concerned is provided with clear and comprehensive information in accordance with Directive 95/46/EC, inter alia about the purposes of the processing, and is offered the right to refuse such processing by the data controller. This shall not prevent any technical storage or access for the sole purpose of carrying out or facilitating the transmission of a communication over an electronic communications network, or as strictly necessary in order to provide an information society service explicitly requested by the subscriber or user."
    <br/>The requirement was implemented in the UK in the Privacy and Electronic Communications (EC Directive) Regulations 2003. In its current (amended) form, Regulation 6 states:
    <br/>"(1) Subject to paragraph (4), a person shall not store or gain access to information stored, in the terminal equipment of a subscriber or user unless the requirements of paragraph (2) are met.
    <br/>(2) The requirements are that the subscriber or user of that terminal equipment - (a) is provided with clear and comprehensive information about the purposes of the storage of, or access to, that information; and (b) has given his or her consent.
    <br/>(3) Where an electronic communications network is used by the same person to store or access information in the terminal equipment of a subscriber or user on more than one occasion, it is sufficient for the purposes of this regulation that the requirements of paragraph (2) are met in respect of the initial use.
    <br/>(3A) For the purposes of paragraph (2), consent may be signified by a subscriber who amends or sets controls on the internet browser which the subscriber uses or by using another application or programme to signify consent.
    <br/>(4) Paragraph (1) shall not apply to the technical storage of, or access to, information - (a) for the sole purpose of carrying out the transmission of a communication over an electronic communications network; or (b) where such storage or access is strictly necessary for the provision of an information society service requested by the subscriber or user."
    <br/>In their original form, these Regulations can be found on the legislation.gov.uk website.
    <br/>* Directive 2002/58/EC (Directive on privacy and electronic communications) - https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32002L0058&from=EN
    <br/>* Privacy and Electronic Communications (EC Directive) Regulations 2003 (original form) - http://www.legislation.gov.uk/uksi/2003/2426/made
    <br/>Section 9.2
    
    
    <br/>Section 9.3
    
    
    <br/>Section 10: Cookies that we use
    
    
    <br/>Section 11: Cookies used by our service providers
    <br/>Does the website serve any third party cookies, analytics cookies or tracking cookies to users?
    <br/>Section 11.2
    
    
    <br/>Section 11.3
    
    
    <br/>Section 12: Managing cookies
    
    
    <br/>Section 12.3
    
     Will the blocking of cookies have a negative effect upon the use of the website from a user perspective?
    <br/>Section 13: Amendments
    
    
    <br/>Section 13.2
    
    
    <br/>Section 13.3
    
     Will you contact users to notify them of changes to this policy?
    <br/>* How will users be notified of changes to the document?
    <br/>Section 14: Our details
    <br/>The provisions here reflect a mixture of EU law and UK law requirements relating to contact information.
    <br/>All services covered by the Ecommerce Directive (which was implemented in the UK through the Electronic Commerce (EC Directive) Regulations 2002) must provide a name, a geographic address (not a P.O. Box number) and an email address.
    <br/>Under distinct UK legislation, UK companies must provide their corporate names, their registration numbers, their place of registration and their registered office address on their websites (although not necessarily in this document). Sole traders and partnerships that carry on a business in the UK under a "business name" (i.e. a name which is not the name of the trader/names of the partners or certain other specified classes of name) must also make certain additional disclosures: (a) in the case of a sole trader, the individual's name; (b) in the case of a partnership, the name of each member of the partnership; and (c) in either case, in relation to each person named, an address in the UK at which service of any document relating in any way to the business will be effective. All operators covered by the Provision of Services Regulations 2009 must also provide a telephone number.
    <br/>* Electronic Commerce (EC Directive) Regulations 2002 (original version) - https://www.legislation.gov.uk/uksi/2002/2013/made
    <br/>* Provision of Services Regulations 2009 - https://www.legislation.gov.uk/uksi/2009/2999
    <br/>* Directive 2000/31/EC (Directive on electronic commerce) - https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32000L0031
    <br/>Section 14.1
    <br/>* What is the name of the company, partnership, individual or other legal person or entity that owns and operates the website? Meeting Pro
    <br/>Section 15: Representatives
    
    
    <br/>Section 15.1
    
    
    <br/>Article 3(2) of the GDPR provides that:
    <br/>"This Regulation applies to the processing of personal data of data subjects who are in the Union by a controller or processor not established in the Union, where the processing activities are related to: (a) the offering of goods or services, irrespective of whether a payment of the data subject is required, to such data subjects in the Union; or (b) the monitoring of their behaviour as far as their behaviour takes place within the Union."
    <br/>Article 27(1) of the GDPR provides that:
    <br/>"Where Article 3(2) applies, the controller or the processor shall designate in writing a representative in the Union."
    <br/>There are however some exceptions here. Article 27(2) of the GDPR provides that:
    <br/>"The obligation laid down in paragraph 1 of this Article shall not apply to: (a) processing which is occasional, does not include, on a large scale, processing of special categories of data as referred to in Article 9(1) or processing of personal data relating to criminal convictions and offences referred to in Article 10, and is unlikely to result in a risk to the rights and freedoms of natural persons, taking into account the nature, context, scope and purposes of the processing; or (b) a public authority or body."
    <br/>Where a representative has been appointed, Article 13(1)(a) of the GDPR provides that:
    <br/>"Where personal data relating to a data subject are collected from the data subject, the controller shall, at the time when personal data are obtained, provide the data subject with all of the following information: (a) the identity and the contact details of the controller and, where applicable, of the controller's representative".
    <br/>* Article 3, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-3-gdpr
    <br/>* Article 27, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-27-gdpr
    <br/>* Article 13, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-13-gdpr/
    <br/>Section 15.2
    
    
    <br/>Section 16: Data protection officer
    
    
    <br/>Section 16.1
    <br/>Some data controllers and data processors will have an obligation to appoint a data protection officer (DPO). The basic obligation is set out in Article 37(1) of the GDPR:
    <br/>"The controller and the processor shall designate a data protection officer in any case where: (a) the processing is carried out by a public authority or body, except for courts acting in their judicial capacity; (b) the core activities of the controller or the processor consist of processing operations which, by virtue of their nature, their scope and/or their purposes, require regular and systematic monitoring of data subjects on a large scale; or (c) the core activities of the controller or the processor consist of processing on a large scale of special categories of data pursuant to Article 9 and personal data relating to criminal convictions and offences referred to in Article 10."
    <br/>Article 13(1) of the GDPR provides that:
    <br/>"Where personal data relating to a data subject are collected from the data subject, the controller shall, at the time when personal data are obtained, provide the data subject with all of the following information ... (b) the contact details of the data protection officer, where applicable".
    <br/>See also Article 14(1)(b).
    <br/>* Insert contact details of the appointed data protection officer (if any).
    <br/>* Article 37, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-37-gdpr
    <br/>* Article 13, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-13-gdpr/
    <br/>* Article 14, Regulation (EU) 2016/679 (General Data Protection Regulation) - https://gdpr-info.eu/art-14-gdpr/

</div>