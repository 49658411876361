import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import './App.css';
import {Landing} from './Landing';
import {Terms} from './Terms';
import {Privacy} from './Privacy';
import {Thankyou} from "./Thankyou";
import Buy from './Buy';
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_CLIENT_STRIPE_PUBLISHABLE_KEY);

export default function App() {
    const [clientSecret, setClientSecret] = useState('');
    const [intentId, setIntentId] = useState('');
    const [currency, setCurrency] = useState('');
    const euroCountries = [
        'Belgium', 'Germany', 'Ireland', 'Spain', 'France', 'Italy', 'Luxembourg', 'Netherlands', 'Austria', 'Portugal', 'Finland', 'Greece', 'Slovenia', 'Cyprus', 'Malta', 'Slovakia', 'Estonia', 'Latvia', 'Lithuania'
    ]
    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch('https://api.ipregistry.co/?key=avstxnxfez3yduep')
            .then(function (response) {
                return response.json();
            })
            .then(function (payload) {
                const country = payload?.location?.country?.name || 'United States';
                // console.log(payload.location.country.name + ', ' + payload.location.city);
                const currency = country === 'United Kingdom' ? 'GBP' : euroCountries.includes(country) ? 'EUR' : 'USD';
                setCurrency(currency);

                fetch(`${process.env.REACT_APP_SERVER_URL}/create-payment-intent`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ items: [{ quantity: 1, currency }] }),
                })
                    .then((res) => res.json())
                    .then((data) => setClientSecret(data.clientSecret) || setIntentId(data.id));
            });

    }, [setCurrency, setClientSecret, setIntentId]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (clientSecret && (
                <Elements options={options} stripe={stripePromise}>
      <div className="container">
          <BrowserRouter>
            <Routes>
                <Route path="/" element={<div><div className="header">Meeting Pro<b><Link to="/buy">Buy</Link></b></div><Outlet></Outlet>
                    <div className="footer">Meeting Pro <b><Link to="/terms">Terms and Conditions</Link> | <Link to="/privacy">Privacy</Link></b></div></div>}>
                  <Route index element={<Landing />} />
                <Route path="buy" element={<Buy currency={currency} intentId={intentId} clientSecret={clientSecret} />} />
                <Route path="terms" element={<Terms />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="thankyou" element={<Thankyou />} />
                <Route path="*" element={<Landing />} />
              </Route>
            </Routes>
          </BrowserRouter>
      </div>
                </Elements>
  ))
}
