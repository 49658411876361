import React from "react";

export const Thankyou = () => {
    const params = new URLSearchParams(window.location.search)
    const firstName = params.get('firstName');
    const lastName = params.get('lastName');
    const email = params.get('email');
    const address1 = params.get('address1');
    const address2 = params.get('address2');
    const address3 = params.get('address3');
    const postCode = params.get('postCode');
    const country = params.get('country');
    const quantity = params.get('quantity');
    const currency = params.get('currency');

    const currencySymbols = {USD: '$', GBP: '£', EUR: '€'};

    return <div className="thankyou">
        <h1>Thank you for your order</h1>
        <h2>You should receive an order confirmation email shortly.</h2>
        <h3>Order Summary:</h3>
        <table>
            <tbody>
                <tr key={1}><td>Quantity: </td><td>{quantity}</td></tr>
                <tr key={2}><td>Price: </td><td>{currencySymbols[currency]}{quantity*99}</td></tr>
                <tr key={3}><td>First Name: </td><td>{firstName}</td></tr>
                <tr key={4}><td>Last Name: </td><td>{lastName}</td></tr>
                <tr key={5}><td>Email: </td><td>{email}</td></tr>
                <tr key={6}><td>Address 1: </td><td>{address1}</td></tr>
                <tr key={7}><td>Address 2: </td><td>{address2}</td></tr>
                <tr key={8}><td>Address 3: </td><td>{address3}</td></tr>
                <tr key={9}><td>Post Code: </td><td>{postCode}</td></tr>
                <tr key={0}><td>Country: </td><td>{country}</td></tr>
            </tbody>
        </table>
        <h2>Any questions? Email: info@meeting-pro.org</h2>
    </div>
}