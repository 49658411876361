import {Link} from "react-router-dom";
import React from "react";

export const Landing = () => <div className="landing">
    <h1>Meeting Pro</h1>
    <h1>// Always Online</h1>
    <h2>Keeps Zoom, Microsoft Teams, Slack and Skype status to ‘Online’ by creating mouse movements
    </h2>
    <h2>100% money back guarantee</h2>
    <h2><b><Link to="/buy">BUY</Link></b></h2>
    <img alt="Product Image" width={600} src="website image section plain.png"/>
</div>